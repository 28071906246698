@use '../../styles/yr' as *;

.edit-custom-location-name-modal__header {
  color: var(--color-text-base);

  padding: size(2);
  padding-top: size(3);
}

.edit-custom-location-name-modal__content {
  padding: size(2);
}

.edit-custom-location-name-modal__button-container {
  display: flex;
  flex-direction: column;
}

.edit-custom-location-name-modal__content-name-input-container {
  border-radius: size(0.5);
  border: 1px solid var(--color-stroke-subtle);
  width: fit-content;
  display: flex;
  align-items: center;
  padding: size(0.5);
  min-width: size(6);
  width: 100%;
  justify-content: space-between;
}

.edit-custom-location-name-modal__content-name-input {
  appearance: none;
  background: transparent;
  border: 0;
  padding: size(1) 0;
  width: 100%;
}

.edit-custom-location-name-modal__toolbar {
  display: flex;
  justify-content: flex-end;
  gap: size(1);

  background: var(--color-background-muted-gray);

  border-radius: 0 0 size(1) size(1);

  padding: size(2);
}

.edit-custom-location-name-modal__location-text {
  padding-top: size(0.5);
}

.edit-custom-location-name-modal__delete-custom-name-button {
  margin-top: size(2);
}
