@use '../../styles/yr' as *;

$background: var(--color-background-base);

.webcam-label {
  align-items: center;
  background: $background;
  border-radius: size(0.5);
  display: flex;
  height: size(3);
  padding: 0 size(0.5);
}

.webcam-label__icon {
  display: block;
  margin-right: size(0.5);
}
