@use "sass:math";

@use '../../styles/yr' as *;

$input-border-active: var(--color-stroke-active);
$input-icon-active: var(--color-fill-base);

@mixin hide-search-input {
  &[aria-expanded='false'] {
    height: 0;
    width: 0;
    padding: 0;
    border: 0;
  }

  // Hide the siblings (e.g. cancel button and search icon )
  &[aria-expanded='false'] ~ * {
    display: none;
  }
}

.search {
  position: relative;
  display: block;
  z-index: $z-index-search;
}

.search--expanded {
  @include mq-lte($mq-675) {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 1;

    .search__input {
      padding: size(2);
      background-color: var(--color-background-base);
    }
  }
}

.search__input-submit {
  @include nrk-sr; // We use nrk-sr because submit button is not detected in all browsers if we use display: none
}

.search__input {
  position: relative;
  display: flex;
  z-index: $z-index-search-input;
}

.search__input-field {
  font-size: rem(16); // Prevent iOS from zooming in when the select element is focused
  line-height: math.div(24, 16);
  -webkit-appearance: none;
  box-shadow: none;
  outline: none;
  width: 100%;
  border-radius: size(0.5);
  border: 1px solid var(--color-stroke-subtle);
  padding: rem(8) size(5) rem(8) size(5);

  background-color: var(--color-background-base);

  &::placeholder {
    color: var(--color-text-subtle);
    text-overflow: ellipsis;
  }

  @include mq-lte($mq-600) {
    background: var(--color-background-muted-core-blue);
    border-color: transparent;
  }

  // Always hidden on mobile
  @include mq-lte($mq-675) {
    @include hide-search-input;
  }
}

.search__input-field--hidden {
  @include hide-search-input;
}

// aria-expanded is set by core-suggest
// https://static.nrk.no/core-components/latest/index.html?core-suggest/readme.md#styling
.search__input-field[aria-expanded='true'] {
  background: var(--color-background-base);
  border-color: $input-border-active;
}

.search__input-field[aria-expanded='true'] + .search__input-icon {
  color: $input-icon-active;
  @include mq-lte($mq-675) {
    left: size(3);
  }
}

.search__input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: size(1);

  @include mq-lte($mq-600) {
    left: size(1);
  }

  color: var(--color-fill-subtle);
}

.search__input-cancel-button {
  text-decoration: none;
  user-select: none;
  border: none;
  background: none;

  @include mq-gt($mq-675) {
    display: none;
  }

  margin-left: size(1);
}

.search__input-field[aria-expanded='false'] ~ .search__input-cancel-button {
  display: none;
}

.search__input-clear-button {
  @include mq-lte($mq-675) {
    display: none;
  }
  position: absolute;
  border: 0;
  top: 50%;
  right: size(1);
  transform: translateY(-50%);
  background-color: transparent;
}

.search__suggestions {
  position: absolute;
  width: 100%;
  background-color: var(--color-background-base);
  // core-suggest will add <mark> elements to highlight the current search word.
  // This removes the default <mark> style
  mark {
    background: none;
    color: inherit;
  }

  @include mq-gt($mq-675) {
    margin-top: size(1);
    border-radius: size(1);
    border: 1px solid var(--color-stroke-elevated);
    overflow: hidden;
    box-shadow: $theme-box-shadow-elevated;
  }

  @include mq-lte($mq-675) {
    height: 100vh;
    top: 0;
    // Suggestions should be scrollable on mobile
    overflow: scroll;

    // We add padding to the top so that search result is displayed underneath input field
    padding-top: rem(72);

    li:first-of-type {
      border-top: $divider-solid;
    }
  }
}

.search__suggested-link {
  position: relative;
  display: block;
  text-decoration: none;
  width: 100%;
  height: size(8);

  padding: 0 size(2) 0 size(2);

  &:focus,
  &:hover {
    // Fallback for browser that does not support focus-within (using focus-within to add background-color to the whole element)
    background-color: var(--color-background-hover);
  }
}

// Override for geolocation button
.search__suggested-link--geolocation {
  color: var(--color-text-link);

  &:hover {
    color: var(--color-text-link);
  }
}

.search__suggested-link--disabled {
  cursor: not-allowed;
  &:focus,
  &:hover {
    // Fallback for browser that does not support focus-within (using focus-within to add background-color to the whole element)
    background-color: var(--color-background-base);
    color: var(--color-text-disabled);
  }
}

.search__suggested-icon {
  .search__suggested-link--favourite & {
    color: var(--color-fill-selected);
  }

  .search__suggested-item--disabled & {
    color: var(--color-stroke-subtle-gray);
  }
}

.search__suggested-content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  .search__suggested-item--disabled & {
    color: var(--color-text-disabled);
  }
}

.search__suggested-icon {
  position: absolute;
  display: block;
  height: size(3);
  width: size(3);
  left: size(2);
  top: 50%;
  transform: translateY(-50%);
}

.search__suggested-content {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: size(4);
}
