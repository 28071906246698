@use '../../styles/yr' as *;

.birkebeiner-page {
  margin: size(1) 0;
}

.birkebeiner-page__road-cams,
.birkebeiner-page__route-forecast,
.birkebeiner-page__road-messages {
  margin-top: size(3);

  @include mq-gt($mq-640) {
    margin-top: size(4);
  }
}

.birkebeiner-page__description {
  margin: size(2) 0;
}
