@use "sass:math";
@use '../../styles/yr' as *;

$background: var(--color-background-base);
$border: var(--color-stroke-subtle-core-blue);
$color: var(--color-fill-active);
$background-active: var(--color-background-base);
$border-active: var(--color-stroke-active);
$background-hover: var(--color-background-muted-core-blue);
$border-hover: var(--color-fill-active);

$button-height: size(4);
$circle-size: size(3);

.info-badge-button {
  display: inline-flex;
  align-items: center;
  border-radius: size(1);
  background-color: var(--color-background-muted-core-blue);
  height: $button-height;
  padding-left: size(1);
  padding-right: size(0.5);
}

.info-badge-button__circle {
  align-items: center;
  background-color: $background;
  border-radius: 50%;
  border: 1px solid $border;
  color: $color;
  cursor: pointer;
  display: inline-flex;
  height: $circle-size;
  justify-content: center;
  margin-left: size(0.5);
  width: $circle-size;
}

.info-badge-button {
  &[aria-pressed='true'] .info-badge-button__circle,
  &[aria-current='true'] .info-badge-button__circle {
    background-color: $background-active;
    border-color: $border-active;
    border-width: 2px;
  }

  @include hover() {
    .info-badge-button__circle {
      background-color: $background-hover;
      border-color: $border-hover;
    }
  }
}
