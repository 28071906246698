@use '../variables/theme' as *;

@mixin backdrop($dark: false) {
  // TODO(tl): Check all backdrops
  // background-color: var(--color-background-darken-gray);
  background-color: var(--color-background-backdrop);
  bottom: 0;
  left: 0;
  opacity: if($dark, 1, 0.7);
  position: fixed;
  right: 0;
  top: 0;
  transition: all 150ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
