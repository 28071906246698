@use '../../styles/yr' as *;

.banner {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: size(1);
  padding: size(2) size(2) size(2) size(2);

  &[data-info-button='true'] {
    padding-right: size(6);
  }

  &[data-type='yellow'] {
    background-color: var(--color-background-subtle-yellow);
  }

  &[data-type='purple'] {
    background: var(--color-background-subtle-purple);
  }
}

.banner__icon-left {
  margin-right: size(1);
}

.banner__info-button {
  position: absolute;
  top: size(2);
  right: size(2);
}

.banner__content {
  display: flex;
  flex-direction: column;
}
