@use '../../styles/yr' as *;

@keyframes loader__bounce-animation {
  0% {
    transform: scale(0.3);
  }
  100% {
    transform: scale(1);
  }
}

.loader--block {
  height: rem(100);
  position: relative;
}

.loader__content {
  display: block;
  height: 3rem;
  left: 50%;
  line-height: 3rem;
  margin-left: -1.5rem;
  margin-top: -1.5rem;
  position: absolute;
  text-align: center;
  top: 50%;
  white-space: nowrap;
  width: 3rem;
}

.loader__animation-bounce {
  @include round;
  animation: loader__bounce-animation 0.4s linear 0s infinite alternate-reverse;
  background-color: var(--color-text-base);
  border: 1px solid var(--color-text-base);
  content: '\a0';
  display: inline-block;
  height: 0.9em;
  width: 0.9em;
}

.loader__animation-bounce + .loader__animation-bounce {
  animation: loader__bounce-animation 0.4s linear -0.2s infinite alternate-reverse;
}

.loader__animation-bounce + .loader__animation-bounce + .loader__animation-bounce {
  animation: loader__bounce-animation 0.4s linear -0.4s infinite alternate-reverse;
}
