@use '../variables/media-queries' as *;
@use '../variables/variables' as *;
@use '../functions/line-height' as *;
@use '../functions/rem' as *;
@use '~@nrk/yr-design-tokens/build/scss/typography' as *;

// For now only used in NowHero
$hero-2-line-height: line-height($typography-line-height-hero-2);
@mixin hero-2() {
  font-size: rem($typography-text-size-hero-2);
  line-height: $hero-2-line-height;
  letter-spacing: $typography-letter-spacing-hero-2;
}

$text-1-line-height: line-height($typography-line-height-text-1);
@mixin text-1() {
  font-size: rem($typography-text-size-text-1);
  line-height: $text-1-line-height;
  letter-spacing: $typography-letter-spacing-text-1;
}

$text-2-line-height: line-height($typography-line-height-text-2);
@mixin text-2() {
  font-size: rem($typography-text-size-text-2);
  line-height: $text-2-line-height;
  letter-spacing: $typography-letter-spacing-text-2;
}

$text-3-line-height: line-height($typography-line-height-text-3);
@mixin text-3() {
  font-size: rem($typography-text-size-text-3);
  line-height: $text-3-line-height;
}

$text-4-line-height: line-height($typography-line-height-text-4);
@mixin text-4() {
  font-size: rem($typography-text-size-text-4);
  line-height: $text-4-line-height;
}

$text-5-line-height: line-height($typography-line-height-text-5);
@mixin text-5() {
  font-size: rem($typography-text-size-text-5);
  line-height: $text-5-line-height;
}

$text-6-line-height: line-height($typography-line-height-text-6);
@mixin text-6 {
  font-size: rem($typography-text-size-text-6);
  line-height: $text-6-line-height;
}

@mixin header-1() {
  @include text-1;
  font-weight: $typography-font-weight-bold; // 600
}

@mixin header-2() {
  @include text-2;
  font-weight: $typography-font-weight-bold; // 600
}

@mixin header-3() {
  @include text-3;
  font-weight: $typography-font-weight-bold; // 600
}

@mixin header-4() {
  @include text-4;
  font-weight: $typography-font-weight-bold; // 600
}

@mixin header-5() {
  @include text-5;
  font-weight: $typography-font-weight-bold; // 600
}
