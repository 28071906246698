@use '../../styles/yr' as *;

$modal-dialog-padding: size(2 * 2);

.edit-custom-location-name-promo {
  padding: 0 size(2) size(2) size(2);
  // Remove modal dialog padding from max-width
  max-width: rem(480px) - $modal-dialog-padding;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.edit-custom-location-name-promo__close-button-contianer {
  position: absolute;
  right: size(2);
}

.edit-custom-location-name-promol__header {
  padding-bottom: size(2);
  color: var(--color-text-darken-core-blue);
}

.edit-custom-location-name-promo__info-text {
  color: var(--color-text-accent-core-blue);

  padding-bottom: 20%;

  @include mq-gt(375px) {
    padding-bottom: size(20);
  }
}
