@use "sass:math";
@use '../../styles/yr' as *;

.icon-button {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: size(0.5);
  &[data-shape='rounded'] {
    border-radius: rem(100px);
  }
}

.icon-button[data-size='small'] {
  $height: rem(24px);
  $width: rem(24px);
  height: $height;
  min-width: $width;
}

.icon-button[data-size='medium'] {
  $height: rem(42px);
  $width: rem(42px);
  height: $height;
  min-width: $width;
}

.icon-button[data-type='default'] {
  &[data-variant='accent'] {
    color: var(--color-button-default-accent-foreground);
    background-color: var(--color-button-default-accent-rest);

    @include hover {
      transition: all 100ms ease-out;
      color: var(--color-button-default-accent-foreground-hover);
      background-color: var(--color-button-default-accent-hover);
    }

    &[aria-pressed='true'] {
      color: var(--color-button-default-accent-foreground-hover);
      background-color: var(--color-button-default-accent-pressed);
    }

    @include active() {
      color: var(--color-button-default-accent-foreground-hover);
      background-color: var(--color-button-default-accent-pressed);
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-default-accent-foreground);
      background-color: var(--color-button-default-accent-rest);
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      background: var(--color-button-disabled-light);

      @include hover {
        color: var(--color-button-disabled-dark);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-current='true'] {
      color: var(--color-button-default-accent-foreground-hover);
      background-color: var(--color-button-default-accent-pressed);
    }
  }

  &[data-variant='neutral'] {
    color: var(--color-button-default-neutral-foreground);
    background-color: var(--color-button-default-neutral-rest);

    @include hover {
      transition: all 100ms ease-out;
      color: var(--color-button-default-neutral-foreground-hover);
      background-color: var(--color-button-default-neutral-hover);
    }

    &[aria-pressed='true'] {
      color: var(--color-button-default-neutral-foreground-hover);
      background-color: var(--color-button-default-neutral-pressed);
    }

    @include active() {
      color: var(--color-button-default-neutral-foreground-hover);
      background-color: var(--color-button-default-neutral-pressed);
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-default-neutral-foreground);
      background-color: var(--color-button-default-neutral-rest);
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      background: var(--color-button-disabled-light);

      @include hover {
        color: var(--color-button-disabled-dark);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-current='true'] {
      color: var(--color-button-default-neutral-foreground-hover);
      background-color: var(--color-button-default-neutral-pressed);
    }
  }

  &[data-variant='critical'] {
    color: var(--color-button-default-critical-foreground);
    background-color: var(--color-button-default-critical-rest);

    @include hover {
      transition: all 100ms ease-out;
      color: var(--color-button-default-critical-foreground-hover);
      background-color: var(--color-button-default-critical-hover);
    }

    &[aria-pressed='true'] {
      color: var(--color-button-default-critical-foreground-hover);
      background-color: var(--color-button-default-critical-pressed);
    }

    @include active() {
      color: var(--color-button-default-critical-foreground-hover);
      background-color: var(--color-button-default-critical-pressed);
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-default-critical-foreground);
      background-color: var(--color-button-default-critical-rest);
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      background: var(--color-button-disabled-light);

      @include hover {
        color: var(--color-button-disabled-dark);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-current='true'] {
      color: var(--color-button-default-critical-foreground-hover);
      background-color: var(--color-button-default-critical-pressed);
    }
  }
}

.icon-button[data-type='ghost'] {
  &[data-variant='accent'] {
    color: var(--color-button-ghost-accent-foreground);
    background-color: var(--color-button-ghost-accent-rest);

    @include hover {
      transition: all 100ms ease-out;
      color: var(--color-button-ghost-accent-foreground-hover);
      background-color: var(--color-button-ghost-accent-hover);
    }

    &[aria-pressed='true'] {
      color: var(--color-button-ghost-accent-foreground-hover);
      background-color: var(--color-button-ghost-accent-pressed);
    }

    @include active() {
      color: var(--color-button-ghost-accent-foreground-hover);
      background-color: var(--color-button-ghost-accent-pressed);
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-ghost-accent-foreground);
      background-color: var(--color-button-ghost-accent-rest);
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      background: var(--color-button-disabled-light);

      @include hover {
        color: var(--color-button-disabled-dark);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-current='true'] {
      color: var(--color-button-ghost-accent-foreground-hover);
      background-color: var(--color-button-ghost-accent-pressed);
    }
  }

  &[data-variant='neutral'] {
    color: var(--color-button-ghost-neutral-foreground);
    background-color: var(--color-button-ghost-neutral-rest);

    @include hover {
      transition: all 100ms ease-out;
      color: var(--color-button-ghost-neutral-foreground-hover);
      background-color: var(--color-button-ghost-neutral-hover);
    }

    &[aria-pressed='true'] {
      color: var(--color-button-ghost-neutral-foreground-hover);
      background-color: var(--color-button-ghost-neutral-pressed);
    }

    @include active() {
      color: var(--color-button-ghost-neutral-foreground-hover);
      background-color: var(--color-button-ghost-neutral-pressed);
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-ghost-neutral-foreground);
      background-color: var(--color-button-ghost-neutral-rest);
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      background: var(--color-button-disabled-light);

      @include hover {
        color: var(--color-button-disabled-dark);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-current='true'] {
      color: var(--color-button-ghost-neutral-foreground-hover);
      background-color: var(--color-button-ghost-neutral-pressed);
    }
  }

  &[data-variant='critical'] {
    color: var(--color-button-ghost-critical-foreground);
    background-color: var(--color-button-ghost-critical-rest);

    @include hover {
      transition: all 100ms ease-out;
      color: var(--color-button-ghost-critical-foreground-hover);
      background-color: var(--color-button-ghost-critical-hover);
    }

    &[aria-pressed='true'] {
      color: var(--color-button-ghost-critical-foreground-hover);
      background-color: var(--color-button-ghost-critical-pressed);
    }

    @include active() {
      color: var(--color-button-ghost-critical-foreground-hover);
      background-color: var(--color-button-ghost-critical-pressed);
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-ghost-critical-foreground);
      background-color: var(--color-button-ghost-critical-rest);
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      background: var(--color-button-disabled-light);

      @include hover {
        color: var(--color-button-disabled-dark);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-current='true'] {
      color: var(--color-button-ghost-critical-foreground-hover);
      background-color: var(--color-button-ghost-critical-pressed);
    }
  }
}

.icon-button[data-type='bleed'] {
  &[data-variant='accent'] {
    color: var(--color-button-bleed-accent-foreground);
    background-color: transparent;

    @include hover {
      transition: all 100ms ease-out;
      color: var(--color-button-bleed-accent-foreground-hover);
      background-color: var(--color-button-bleed-accent-hover);
    }

    &[aria-pressed='true'] {
      color: var(--color-button-bleed-accent-foreground-hover);
      background-color: var(--color-button-bleed-accent-pressed);
    }

    @include active() {
      color: var(--color-button-bleed-accent-foreground-hover);
      background-color: var(--color-button-bleed-accent-pressed);
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-bleed-accent-foreground);
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      background: var(--color-button-disabled-light);

      @include hover {
        color: var(--color-button-disabled-dark);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-current='true'] {
      color: var(--color-button-bleed-accent-foreground-hover);
      background-color: var(--color-button-bleed-accent-pressed);
    }
  }

  &[data-variant='neutral'] {
    color: var(--color-button-bleed-neutral-foreground);
    background-color: transparent;

    @include hover {
      transition: all 100ms ease-out;
      color: var(--color-button-bleed-neutral-foreground-hover);
      background-color: var(--color-button-bleed-neutral-hover);
    }

    &[aria-pressed='true'] {
      color: var(--color-button-bleed-neutral-foreground-hover);
      background-color: var(--color-button-bleed-neutral-pressed);
    }

    @include active() {
      color: var(--color-button-bleed-neutral-foreground-hover);
      background-color: var(--color-button-bleed-neutral-pressed);
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-bleed-neutral-foreground);
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      background: var(--color-button-disabled-light);

      @include hover {
        color: var(--color-button-disabled-dark);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-current='true'] {
      color: var(--color-button-bleed-neutral-foreground-hover);
      background-color: var(--color-button-bleed-neutral-pressed);
    }
  }

  &[data-variant='critical'] {
    color: var(--color-button-bleed-critical-foreground);
    background-color: transparent;

    @include hover {
      transition: all 100ms ease-out;
      color: var(--color-button-bleed-critical-foreground-hover);
      background-color: var(--color-button-bleed-critical-hover);
    }

    &[aria-pressed='true'] {
      color: var(--color-button-bleed-critical-foreground-hover);
      background-color: var(--color-button-bleed-critical-pressed);
    }

    @include active() {
      color: var(--color-button-bleed-critical-foreground-hover);
      background-color: var(--color-button-bleed-critical-pressed);
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-bleed-critical-foreground);
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      background: var(--color-button-disabled-light);

      @include hover {
        color: var(--color-button-disabled-dark);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-current='true'] {
      color: var(--color-button-bleed-critical-foreground-hover);
      background-color: var(--color-button-bleed-critical-pressed);
    }
  }
}
