@use '../../styles/yr' as *;

.location-details-panel {
  padding: size(2);

  display: flex;
  flex-direction: column;
  gap: size(1);
}

.location-details-panel__button {
  margin-bottom: size(1);
}
