@use '../../styles/yr' as *;

.text-1 {
  @include text-1;
}

.text-2 {
  @include text-2;
}

.text-3 {
  @include text-3;
}

.text-4 {
  @include text-4;
}

.text-5 {
  @include text-5;
}

.text-6 {
  @include text-6;
}

.text--is-link {
  text-decoration: underline;

  @include hover {
    text-decoration: none;
  }
}

.text-weight-normal {
  font-weight: $typography-font-weight-normal;
}

.text-weight-bold {
  font-weight: $typography-font-weight-bold;
}

.text-tabular-nums {
  font-variant-numeric: tabular-nums;
}

.text--color-primary {
  color: var(--color-text-base);
}

.text--color-secondary {
  color: var(--color-text-subtle);
}

.text--color-error {
  color: var(--color-text-error);
}

.text--color-negative {
  color: var(--color-text-inverse);
}

.text--color-link {
  color: var(--color-text-link);
}

.text--color-inherit {
  color: inherit;
}
