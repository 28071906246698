@use '../../styles/yr' as *;

.survey {
  bottom: size(4);
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all $notification-transition;
  z-index: $z-index-survey;
}

.survey--is-visible {
  opacity: 1;
  transform: none;
}

.survey__box {
  background-color: var(--color-background-accent-core-blue);
  border-radius: size(1);
  box-shadow: $theme-box-shadow-elevated;
  color: var(--color-text-white);
  display: inline-block;
  margin: 0 size(2);
  max-width: 100%;
  padding: size(1) size(2);
  pointer-events: all;
  position: relative;
  width: rem(600px);
}

.survey__content {
  align-items: center;
  display: flex;
}

.survey__text {
  flex: 1;
}

.survey__title {
  font-weight: $typography-font-weight-bold;
}

.survey__ok-button,
.survey__close-button {
  margin-left: size(1);
}
