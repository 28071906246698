@use '../../styles/yr' as *;

.served-by {
  @include mq-gte($mq-675) {
    position: relative;
    height: size(5);
  }

  // We use grid when rendering servedBy inside the Menu
  @include mq-lt($mq-675) {
    display: grid;
    grid-template-areas:
      'text text'
      'nrk met';
    grid-template-columns: auto auto;
    grid-template-rows: 1fr 1fr;
    margin: 0 size(2);
  }
}

.served-by__text {
  position: absolute;
  top: size(2);
  right: 0;

  a {
    text-decoration: none;
  }
}

.served-by__logo-text {
  display: inline-block;
  grid-area: text;
  align-self: center;

  @include mq-lt($mq-675) {
    justify-self: center;
    margin-bottom: size(2);
  }
}

.served-by__logo-link {
  display: inline-block;
  align-self: center;

  & > img {
    height: 100%;
  }

  // We use padding since grid-gap is not supported in older browsers
  padding-left: size(2);

  @include mq-lt($mq-675) {
    justify-self: center;
    padding: 0;
  }
}

.served-by__logo-link--nrk {
  grid-area: nrk;
  height: size(3);
}

.served-by__logo-link--met {
  grid-area: met;
  // Adjust the height of the MET logo so the top and bottom
  // of the text is the same height as the NRK logo.
  height: rem(34px);
}
