@use '../../styles/yr' as *;

.login-promo-banner {
  padding: size(2);

  &.login-promo-banner--login-promo-purple {
    background-color: var(--color-background-subtle-purple);
    color: var(--color-text-darken-cool-pink);
  }

  &.login-promo-banner--login-promo-orange {
    background-color: var(--color-background-subtle-cool-orange);
    color: var(--color-text-white);
  }

  &.login-promo-banner--login-promo-blue {
    background-color: var(--color-background-subtle-core-blue);
    color: var(--color-text-darken-core-blue);
  }
}

.login-promo-banner__content {
  display: flex;
  justify-content: space-between;
  gap: size(1);

  align-items: center;
}

.login-promo-banner__text {
  display: inline;
  gap: size(1);

  align-items: center;
}

.login-promo-banner__badge-container {
  display: inline;
  margin-right: size(0.5);
}

// OVERRIDE(danjoh): Override button css to customize login promo colors
.login-promo-banner {
  &.login-promo-banner--login-promo-orange {
    .login-promo-banner__button--login-promo-orange {
      color: var(--color-button-login-promo-orange-foreground);
      background-color: var(--color-button-login-promo-orange-rest);

      &:hover {
        background-color: var(--color-button-login-promo-orange-hover);
        color: var(--color-button-login-promo-orange-foreground-hover);
      }
    }
  }

  &.login-promo-banner--login-promo-blue {
    .login-promo-banner__button--login-promo-blue {
      color: var(--color-button-login-promo-blue-foreground);
      background-color: var(--color-button-login-promo-blue-rest);

      &:hover {
        background-color: var(--color-button-login-promo-blue-hover);
        color: var(--color-button-login-promo-blue-foreground-hover);
      }
    }
  }

  &.login-promo-banner--login-promo-purple {
    .login-promo-banner__button--login-promo-purple {
      color: var(--color-button-login-promo-purple-foreground);
      background-color: var(--color-button-login-promo-purple-rest);

      &:hover {
        background-color: var(--color-button-login-promo-purple-hover);
        color: var(--color-background-subtle-purple);
      }
    }
  }
}
