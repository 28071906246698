@use '../../styles/yr' as *;

$close-button-background-color: $base-color-white;

.animatable-modal {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-animatable-modal;
}

.animatable-modal__scroll-container {
  bottom: 0;
  left: 0;
  overflow: scroll;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-overflow-scrolling: touch;

  // We use `!important` to overwrite the inline style set by @nrk/core-dialog
  z-index: 1 !important;
}

.animatable-modal__flex-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  // Render in front of `.animatable-modal__backdrop`
  z-index: 1;

  // This element needs to be at least as tall as the viewport
  // in order to vertically center its dialog child when the
  // dialog is shorter than the height of the viewport.
  min-height: 100%;
}

.animatable-modal__container {
  background-color: var(--color-background-base);
  position: relative;
  box-shadow: $theme-box-shadow-elevated;
  transform-origin: center center;
  width: 100%;
  height: 100%;

  // Prevent the dialog from growing too large in IE11 because of the
  // feature promo image.
  // See https://github.com/philipwalton/flexbugs/issues/75#issuecomment-161800607
  transform: scale(0);
  &.animatable-modal__container--rounded {
    border-radius: size(1);
  }
}

.animatable-modal__container--opening {
  transform: scale(1);
  transition: transform $ui-transition;
}

.animatable-modal__container--closing {
  transform: scale(0);
  transition: transform $ui-transition;
}

.animatable-modal__backdrop {
  @include backdrop;
  opacity: 0;
}

.animatable-modal__backdrop--opening {
  opacity: 0.6;
  transition: all $ui-transition;
}

.animatable-modal__backdrop--closing {
  opacity: 0;
  transition: all $ui-transition;
}

.animatable-modal__close-button {
  position: absolute;
  top: size(2);
  right: size(2);

  // Render the close button with a higher z index than its following sibling elements.
  // This should be unnecessary since due to source order the close button is
  // rendered in front of its following sibling elements (assuming they don't set a z-index
  // higher than 0), but Chrome has a bug where if a video element is rendered behind this
  // close button, the close button is unclickable even if the close button is rendered in
  // front of the video element. Explicitly setting a z-index higher than 0 is a workaround
  // for this bug.
  z-index: 1;
}

.animatable-modal__close-button--on-top-of-image {
  background-color: $close-button-background-color;
  border-radius: 50%;
  box-shadow: $theme-box-shadow;
}

.animatable-modal__transition-end {
  background-color: transparent;
  opacity: 0.1;
}

.animatable-modal__transition-end--closing {
  opacity: 0;
  transition: all $ui-transition;
}

.animatable-modal__flex-container--padding {
  .animatable-modal__container {
    max-width: rem(374px);
  }

  padding: 0 size(0.5);
}
