.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app__main {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}
