@use '../../styles/yr' as *;

.toast {
  border: 0;
  border-radius: 2px;
  box-shadow: $theme-box-shadow-elevated;
  position: fixed;
  text-align: left;
  width: 30%;
  opacity: 0;

  display: flex;
  align-items: center;

  height: rem(62px);

  z-index: $z-index-app-status;
  transition: all $notification-transition;

  @include mq-lte($mq-990) {
    width: 50%;
  }

  @include mq-lte($mq-675) {
    bottom: 0;
    left: 0;
    padding: 1rem 3rem 1rem 1rem;
    right: 0;
    width: 100%;
    border-radius: 0;
    transform: translateY(100%);
  }

  @include mq-gt($mq-675) {
    bottom: 4rem;
    padding: size(2);
    right: 4rem;
    transform: translateX(20rem);
  }
}

.toast__icon {
  margin-right: size(1);
}

.toast__icon--star-filled {
  color: var(--color-fill-selected);
}

.toast--visible {
  opacity: 1;
  @include mq-lte($mq-675) {
    transform: translateY(0);
  }

  @include mq-gt($mq-675) {
    transform: translateX(0);
  }
}

.toast--notification {
  background-color: var(--color-background-muted-core-blue);
  border: 1px solid var(--color-stroke-subtle-core-blue);
}

.toast--error {
  background-color: var(--color-background-muted-red);
  border: 1px solid var(--color-stroke-subtle-red);
}

.toast__close-button {
  $button-size: 3rem;
  $icon-size: 2rem;

  color: inherit;
  height: $button-size;
  position: absolute;
  right: 0;
  top: 0;
  width: $button-size;
}

.toast__close-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
