@use '../../styles/yr' as *;

.skip-to-content {
  height: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-index-popover;
  text-align: center;
}

.skip-to-content__link {
  opacity: 0;
  position: relative;
  top: -2.5em;
  transition: none;
}

.skip-to-content__link:focus {
  opacity: 1;
  top: 0;
}
