@use '../../styles/yr' as *;

.error-boundary {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.error-boundary__logo-link {
  display: block;
}

.error-boundary__logo {
  margin-bottom: size(2);
  width: rem(100);
  height: rem(100);
}
