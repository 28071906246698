@use "sass:math";
@use '../../styles/yr' as *;

$border-width: 2px;

.button {
  @include header-4;

  // Set up transparent border that is used later
  border: 2px solid transparent;

  cursor: pointer;
  display: inline-flex;
  position: relative;
  align-items: center;
  text-decoration: none;

  // If a parent component makes the button wider we want its content to be centered
  justify-content: center;
  gap: size(0.5);

  border-radius: size(0.5);
  &[data-shape='rounded'] {
    border-radius: rem(100px);
  }
}

.button[data-type='default'] {
  &[data-variant='accent'] {
    color: var(--color-button-default-accent-foreground);
    background-color: var(--color-button-default-accent-rest);
    border-color: transparent;

    &:hover {
      transition: all 100ms ease-out;
      color: var(--color-button-default-accent-foreground-hover);
      background-color: var(--color-button-default-accent-hover);
      border-color: transparent;
    }

    @include active() {
      color: var(--color-button-default-accent-foreground-hover);
      background-color: var(--color-button-default-accent-pressed);
      border-color: transparent;
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-default-accent-foreground);
      background-color: var(--color-button-default-accent-rest);
      border-color: transparent;
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      border-color: var(--color-button-disabled-light);
      background: var(--color-button-disabled-light);

      &:hover {
        color: var(--color-button-disabled-dark);
        border-color: var(--color-button-disabled-light);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-pressed='true'] {
      color: var(--color-button-default-accent-foreground-hover);
      background-color: var(--color-button-default-accent-pressed);
      border-color: transparent;
    }

    &[aria-current='true'] {
      color: var(--color-button-default-accent-foreground-hover);
      background-color: var(--color-button-default-accent-pressed);
      border-color: transparent;
    }

    &[aria-expanded='true'] {
      color: var(--color-button-default-accent-foreground-hover);
      background-color: var(--color-button-default-accent-pressed);
      border-color: transparent;
    }
  }

  &[data-variant='neutral'] {
    color: var(--color-button-default-neutral-foreground);
    background-color: var(--color-button-default-neutral-rest);
    border-color: transparent;

    &:hover {
      transition: all 100ms ease-out;
      color: var(--color-button-default-neutral-foreground-hover);
      background-color: var(--color-button-default-neutral-hover);
      border-color: transparent;
    }

    @include active() {
      color: var(--color-button-default-neutral-foreground-hover);
      background-color: var(--color-button-default-neutral-pressed);
      border-color: transparent;
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-default-neutral-foreground);
      background-color: var(--color-button-default-neutral-rest);
      border-color: transparent;
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      border-color: var(--color-button-disabled-light);
      background: var(--color-button-disabled-light);

      &:hover {
        color: var(--color-button-disabled-dark);
        border-color: var(--color-button-disabled-light);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-pressed='true'] {
      color: var(--color-button-default-neutral-foreground-hover);
      background-color: var(--color-button-default-neutral-pressed);
      border-color: transparent;
    }

    &[aria-current='true'] {
      color: var(--color-button-default-neutral-foreground-hover);
      background-color: var(--color-button-default-neutral-pressed);
      border-color: transparent;
    }

    &[aria-expanded='true'] {
      color: var(--color-button-default-neutral-foreground-hover);
      background-color: var(--color-button-default-neutral-pressed);
      border-color: transparent;
    }
  }

  &[data-variant='critical'] {
    color: var(--color-button-default-critical-foreground);
    background-color: var(--color-button-default-critical-rest);
    border-color: transparent;

    &:hover {
      transition: all 100ms ease-out;
      color: var(--color-button-default-critical-foreground-hover);
      background-color: var(--color-button-default-critical-hover);
      border-color: transparent;
    }

    @include active() {
      color: var(--color-button-default-critical-foreground-hover);
      background-color: var(--color-button-default-critical-pressed);
      border-color: transparent;
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-default-critical-foreground);
      background-color: var(--color-button-default-critical-rest);
      border-color: transparent;
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      border-color: var(--color-button-disabled-light);
      background: var(--color-button-disabled-light);

      &:hover {
        color: var(--color-button-disabled-dark);
        border-color: var(--color-button-disabled-light);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-pressed='true'] {
      color: var(--color-button-default-critical-foreground-hover);
      background-color: var(--color-button-default-critical-pressed);
      border-color: transparent;
    }

    &[aria-current='true'] {
      color: var(--color-button-default-critical-foreground-hover);
      background-color: var(--color-button-default-critical-pressed);
      border-color: transparent;
    }

    &[aria-expanded='true'] {
      color: var(--color-button-default-critical-foreground-hover);
      background-color: var(--color-button-default-critical-pressed);
      border-color: transparent;
    }
  }
}

.button[data-type='ghost'] {
  &[data-variant='accent'] {
    color: var(--color-button-ghost-accent-foreground);
    background-color: var(--color-button-ghost-accent-rest);
    border-color: transparent;

    &:hover {
      transition: all 100ms ease-out;
      color: var(--color-button-ghost-accent-foreground-hover);
      background-color: var(--color-button-ghost-accent-hover);
      border-color: transparent;
    }

    @include active() {
      color: var(--color-button-ghost-accent-foreground-hover);
      background-color: var(--color-button-ghost-accent-pressed);
      border-color: transparent;
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-ghost-accent-foreground);
      background-color: var(--color-button-ghost-accent-rest);
      border-color: transparent;
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      border-color: var(--color-button-disabled-light);
      background: var(--color-button-disabled-light);

      &:hover {
        color: var(--color-button-disabled-dark);
        border-color: var(--color-button-disabled-light);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-pressed='true'] {
      color: var(--color-button-ghost-accent-foreground-hover);
      background-color: var(--color-button-ghost-accent-pressed);
      border-color: transparent;
    }

    &[aria-current='true'] {
      color: var(--color-button-ghost-accent-foreground-selected);
      background-color: var(--color-button-ghost-accent-selected);
      border-color: transparent;
    }

    &[aria-expanded='true'] {
      color: var(--color-button-ghost-accent-foreground-hover);
      background-color: var(--color-button-ghost-accent-pressed);
      border-color: transparent;
    }
  }

  &[data-variant='neutral'] {
    color: var(--color-button-ghost-neutral-foreground);
    background-color: var(--color-button-ghost-neutral-rest);
    border-color: transparent;

    &:hover {
      transition: all 100ms ease-out;
      color: var(--color-button-ghost-neutral-foreground-hover);
      background-color: var(--color-button-ghost-neutral-hover);
      border-color: transparent;
    }

    @include active() {
      color: var(--color-button-ghost-neutral-foreground-hover);
      background-color: var(--color-button-ghost-neutral-pressed);
      border-color: transparent;
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-ghost-neutral-foreground);
      background-color: var(--color-button-ghost-neutral-rest);
      border-color: transparent;
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      border-color: var(--color-button-disabled-light);
      background: var(--color-button-disabled-light);

      &:hover {
        color: var(--color-button-disabled-dark);
        border-color: var(--color-button-disabled-light);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-pressed='true'] {
      color: var(--color-button-ghost-neutral-foreground-hover);
      background-color: var(--color-button-ghost-neutral-pressed);
      border-color: transparent;
    }

    &[aria-current='true'] {
      color: var(--color-button-ghost-neutral-foreground-selected);
      background-color: var(--color-button-ghost-neutral-selected);
      border-color: transparent;
    }

    &[aria-expanded='true'] {
      color: var(--color-button-ghost-neutral-foreground-hover);
      background-color: var(--color-button-ghost-neutral-pressed);
      border-color: transparent;
    }
  }

  &[data-variant='critical'] {
    color: var(--color-button-ghost-critical-foreground);
    background-color: var(--color-button-ghost-critical-rest);
    border-color: transparent;

    &:hover {
      transition: all 100ms ease-out;
      color: var(--color-button-ghost-critical-foreground-hover);
      background-color: var(--color-button-ghost-critical-hover);
      border-color: transparent;
    }

    @include active() {
      color: var(--color-button-ghost-critical-foreground-hover);
      background-color: var(--color-button-ghost-critical-pressed);
      border-color: transparent;
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-ghost-critical-foreground);
      background-color: var(--color-button-ghost-critical-rest);
      border-color: transparent;
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      border-color: var(--color-button-disabled-light);
      background: var(--color-button-disabled-light);

      &:hover {
        color: var(--color-button-disabled-dark);
        border-color: var(--color-button-disabled-light);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-pressed='true'] {
      color: var(--color-button-ghost-critical-foreground-hover);
      background-color: var(--color-button-ghost-critical-pressed);
      border-color: transparent;
    }

    &[aria-current='true'] {
      color: var(--color-button-ghost-critical-foreground-selected);
      background-color: var(--color-button-ghost-critical-selected);
      border-color: transparent;
    }

    &[aria-expanded='true'] {
      color: var(--color-button-ghost-critical-foreground-hover);
      background-color: var(--color-button-ghost-critical-pressed);
      border-color: transparent;
    }
  }
}

.button[data-type='bleed'] {
  &[data-variant='accent'] {
    color: var(--color-button-bleed-accent-foreground);
    border-color: transparent;

    &:hover {
      transition: all 100ms ease-out;
      color: var(--color-button-bleed-accent-foreground-hover);
      background-color: var(--color-button-bleed-accent-hover);
      border-color: transparent;
    }

    @include active() {
      color: var(--color-button-bleed-accent-foreground-hover);
      background-color: var(--color-button-bleed-accent-pressed);
      border-color: transparent;
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-bleed-accent-foreground);
      border-color: transparent;
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      border-color: var(--color-button-disabled-light);
      background: var(--color-button-disabled-light);

      &:hover {
        color: var(--color-button-disabled-dark);
        border-color: var(--color-button-disabled-light);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-pressed='true'] {
      color: var(--color-button-bleed-accent-foreground-hover);
      background-color: var(--color-button-bleed-accent-pressed);
      border-color: transparent;
    }

    &[aria-current='true'] {
      color: var(--color-button-bleed-accent-foreground-hover);
      background-color: var(--color-button-bleed-accent-pressed);
      border-color: transparent;
    }

    &[aria-expanded='true'] {
      color: var(--color-button-bleed-accent-foreground-hover);
      background-color: var(--color-button-bleed-accent-pressed);
      border-color: transparent;
    }
  }

  &[data-variant='neutral'] {
    color: var(--color-button-bleed-neutral-foreground);
    border-color: transparent;

    &:hover {
      transition: all 100ms ease-out;
      color: var(--color-button-bleed-neutral-foreground-hover);
      background-color: var(--color-button-bleed-neutral-hover);
      border-color: transparent;
    }

    @include active() {
      color: var(--color-button-bleed-neutral-foreground-hover);
      background-color: var(--color-button-bleed-neutral-pressed);
      border-color: transparent;
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-bleed-neutral-foreground);
      border-color: transparent;
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      border-color: var(--color-button-disabled-light);
      background: var(--color-button-disabled-light);

      &:hover {
        color: var(--color-button-disabled-dark);
        border-color: var(--color-button-disabled-light);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-pressed='true'] {
      color: var(--color-button-bleed-neutral-foreground-hover);
      background-color: var(--color-button-bleed-neutral-pressed);
      border-color: transparent;
    }
    &[aria-current='true'] {
      color: var(--color-button-bleed-neutral-foreground-hover);
      background-color: var(--color-button-bleed-neutral-pressed);
      border-color: transparent;
    }

    &[aria-expanded='true'] {
      color: var(--color-button-bleed-neutral-foreground-hover);
      background-color: var(--color-button-bleed-neutral-pressed);
      border-color: transparent;
    }
  }

  &[data-variant='critical'] {
    color: var(--color-button-bleed-critical-foreground);
    border-color: transparent;

    &:hover {
      transition: all 100ms ease-out;
      color: var(--color-button-bleed-critical-foreground-hover);
      background-color: var(--color-button-bleed-critical-hover);
      border-color: transparent;
    }

    @include active() {
      color: var(--color-button-bleed-critical-foreground-hover);
      background-color: var(--color-button-bleed-critical-pressed);
      border-color: transparent;
    }

    &:focus,
    &:focus-within {
      color: var(--color-button-bleed-critical-foreground);
      border-color: transparent;
      // Since our default focus style has the same color as this button, we add a inset box-shadow so that the button stands out when focused
      .is-tabbing & {
        box-shadow: 0 0 0 4px var(--color-background-base) inset;
      }
    }

    // We can use the `disabled` attribute to style disabled buttons,
    // but when the select element is disabled we can't add a `disabled`
    // attribute to its parent label element so we use `data-disabled="true"`
    // instead.
    &[data-disabled='true'],
    &[disabled] {
      color: var(--color-button-disabled-dark);
      border-color: var(--color-button-disabled-light);
      background: var(--color-button-disabled-light);

      &:hover {
        color: var(--color-button-disabled-dark);
        border-color: var(--color-button-disabled-light);
        background: var(--color-button-disabled-light);
        cursor: default;
      }
    }

    &[aria-pressed='true'] {
      color: var(--color-button-bleed-critical-foreground-hover);
      background-color: var(--color-button-bleed-critical-pressed);
      border-color: transparent;
    }

    &[aria-current='true'] {
      color: var(--color-button-bleed-critical-foreground-hover);
      background-color: var(--color-button-bleed-critical-pressed);
      border-color: transparent;
    }

    &[aria-expanded='true'] {
      color: var(--color-button-bleed-critical-foreground-hover);
      background-color: var(--color-button-bleed-critical-pressed);
      border-color: transparent;
    }
  }
}

.button[data-size='small'] {
  $height: rem(34px);
  @include text-4;
  height: $height;

  padding: 0 size(1) - rem($border-width);
}

.button[data-size='medium'] {
  $height: rem(42px);
  height: $height;

  padding: 0 size(2) - rem($border-width);
}

.button[data-size='large'] {
  $height: rem(56px);
  height: $height;

  padding: 0 size(3) - rem($border-width);
}

.button__select {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-appearance: none;

  &[disabled] {
    &:hover {
      cursor: default;
    }
  }

  option {
    // TODO(tl): Get a new name for the css-variable
    background: var(--color-button-big-button-rest);
  }
}

.button__content-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
