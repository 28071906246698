@use '../../styles/yr' as *;

.webcam-card {
  position: relative;
  z-index: 0;
}

.webcam-card__button {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: size(1) size(2);
  text-align: left;
  z-index: 1;

  // We remove the default focus style from the link itself
  // and add it to the larger pseudo-element itself so the whole
  // list item gets a focus outline when the date link is focused.
  &:focus {
    outline: 0;

    &:after {
      @include focus-style;
    }
  }
}

.webcam-card__tint {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // TODO(tl): Get colors from yr-design-system when ready.
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
}

.webcam-card__text {
  margin-top: auto;
}

.webcam-card__image {
  width: 100%;
  height: rem(163px);
  object-fit: cover;
}

.webcam-card__direction-label {
  position: absolute;
  top: size(1);
  left: size(1);
}
