.weather-symbol {
  // We define width and height here since IE11 needs this to properly render
  // the symbol row in detailed graph
  height: auto;
  width: 100%;
}

.weather-symbol__img {
  height: auto;
}
