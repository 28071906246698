@use '../../styles/yr' as *;

.error-state {
  background-color: var(--color-background-elevated);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.error-state--small {
  padding: size(1) size(2);
}

.error-state--large {
  padding: size(10) size(5);
  max-height: rem(254px);
}

.error-state__icon {
  color: var(--color-fill-error);
  margin-bottom: size(1);
}
