@use '../../styles/yr' as *;

$mq-layout-two-columns: 850px;

$mq-layout-medium: 600px;
$mq-layout-large: 920px;

.page-footer {
  padding-top: size(3);
  padding-bottom: size(10);
}

.page-footer__grid {
  border-top: 1px solid var(--color-stroke-subtle-core-blue);
  padding-top: size(5);
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto size(4) auto size(1) auto size(2) auto size(2) auto;
  grid-template-areas:
    'toolbar'
    '.'
    'served-by'
    '.'
    'logos'
    '.'
    'text'
    '.'
    'links';
}

.page-footer__served-by {
  grid-area: served-by;
  @include mq-lt($mq-layout-large) {
    text-align: center;
  }
}

.page-footer__logos {
  grid-area: logos;
  display: flex;
  align-items: center;
  height: rem(42px); // Same height as the toolbar buttons

  @include mq-lt($mq-layout-large) {
    justify-content: center;
  }
}

.page-footer__logo-link {
  display: block;
  flex-shrink: 0; // Prevent the logos from shrinking
}

.page-footer__logo-link--met {
  margin-left: size(5);
}

.page-footer__text {
  grid-area: text;
  display: flex;
  flex-wrap: wrap;

  @include mq-lt($mq-layout-large) {
    justify-content: center;
  }
}

.page-footer__copyright-text {
  text-align: center;
}

.page-footer__toolbar {
  grid-area: toolbar;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  // Negative margin to counteract the margin of each toolbar item
  margin: size(-1);

  // Add a gap between each toolbar item.
  // Ideally we'd just use `gap: size(2)` if it were supported in all browsers.
  & > * {
    margin: size(0.5);
  }
}

.page-footer__links {
  display: flex;
  flex-wrap: wrap;
  grid-area: links;

  @include mq-lt($mq-layout-large) {
    justify-content: center;
  }
}

.page-footer__links-list {
  display: flex;

  // Negative margin to counteract the margin of the leftmost and rightmost list item
  margin: 0 size(-0.5);

  @include mq-lt($mq-layout-large) {
    justify-content: center;
    flex-wrap: wrap;
    margin: size(0.5) size(-0.5);
  }
}

.page-footer__links-list-divider {
  &:after {
    color: var(--color-stroke-active);
    margin: 0 size(0.5);
    content: '|';
  }
  @include mq-lt($mq-layout-large) {
    max-height: 0px;
    &:after {
      content: '';
    }
  }
}

.page-footer__links-list li {
  margin: 0 size(0.5);
}

.page-footer__links-list a {
  color: var(--color-text-link);
  white-space: nowrap;
}
