@use '../../styles/yr' as *;

.temperature {
  display: inline-block;
}

.temperature--cold-primary {
  color: var(--color-text-weather-temperature-minus);
}

.temperature--warm-primary {
  color: var(--color-text-weather-temperature-plus);
}

.temperature--cold-secondary {
  color: var(--color-text-darken-core-blue);
}

.temperature--warm-secondary {
  color: var(--color-text-darken-red);
}
