@use '../../styles/yr' as *;

.warnings-icon-group {
  display: inline-block;
  position: relative;

  // Make z index of descendants local
  z-index: 0;
}

.warnings-icon-group__list {
  display: flex;
  position: relative;
}

.warnings-icon-group__icon {
  display: inline-block;
}

.warnings-icon-group__icon + .warnings-icon-group__icon {
  margin-left: size(1);
}

// Stack the warning icons
.warnings-icon-group--stack {
  .warnings-icon-group__list {
    height: size(4);
    width: size(8);
  }

  .warnings-icon-group__icon {
    // Position the first three icons stacked on top of each other
    position: absolute;
    margin-right: 0;

    // The first warnings is the first the closest in time and is most important to display.
    // We display this icon furthest to the left and in front of the others.
    &:nth-child(1) {
      transform: translate(0px, 0);
      z-index: 3;
    }

    &:nth-child(2) {
      transform: translate(50%, 0);
      z-index: 2;
    }

    &:nth-child(3) {
      transform: translate(100%, 0);
      z-index: 1;
    }
  }

  .warnings-icon-group__icon + .warnings-icon-group__icon {
    margin-left: 0;
  }
}
