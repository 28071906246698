@use '../../styles/yr' as *;

.header-1 {
  @include header-1;
}
.header-2 {
  @include header-2;
}
.header-3 {
  @include header-3;
}
.header-4 {
  @include header-4;
}
.header-5 {
  @include header-5;
}

.heading--color-primary {
  color: var(--color-text-base);
}

.heading--color-negative {
  color: var(--color-text-inverse);
}

.heading--color-inherit {
  color: inherit;
}
