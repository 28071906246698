@use '../yr' as *;

#app-root {
  height: 100%;
  position: relative;
  transition: background-color $ui-transition;

  // Contain z indexes, most importantly the cloud background image on the home page
  z-index: 0;
}

#page-content {
  outline: none; // Remove outline when focused with the 'skip-to-content' link
}

// Horizontally centered layout container with a max width
.layout-container {
  margin: 0 auto;
  max-width: $layout-width;
  position: relative;

  // The layout container always has horizontal padding on larger viewports
  @include mq-gt($mq-675) {
    max-width: $layout-width + size(2 * $layout-size-horizontal);
    padding: 0 size($layout-size-horizontal);
  }
}

// Layout container with padding on all viewports
.layout-container--with-padding {
  max-width: $layout-width + size(2 * $layout-size-horizontal);
  padding: 0 size($layout-size-horizontal);
}

// Escape the horizontal padding of an ancestor layout container
.escape-layout-container-padding {
  .layout-container & {
    @include mq-gt($mq-675) {
      margin-right: size(-$layout-size-horizontal);
      margin-left: size(-$layout-size-horizontal);
    }
  }

  .layout-container--with-padding & {
    margin-right: size(-$layout-size-horizontal);
    margin-left: size(-$layout-size-horizontal);
  }
}
