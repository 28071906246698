@use '../../styles/yr' as *;

.sync-locations-from-local-storage__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: size(2);
}

.sync-locations-from-local-storage__info-text {
  padding: size(2);
}

.sync-locations-from-local-storage__location-list {
  display: flex;
  flex-direction: column;
  gap: size(0.5);

  max-height: rem(200px);
  overflow: scroll;

  border-radius: size(1);
  padding: size(1);
  margin: 0 size(2) size(2) size(2);
  background-color: var(--color-background-lower);
}

.sync-locations-from-local-storage__location-list-item {
  display: flex;
  align-items: center;
  gap: size(1);
}

.sync-locations-from-local-storage__location-list-item:not(:last-child) {
  border-bottom: 1px solid var(--color-stroke-muted-core-blue);
  padding-bottom: size(0.5);
}

.sync-locations-from-local-storage__location-list-item-star {
  color: var(--color-fill-selected);
}

.sync-locations-from-local-storage__buttons {
  display: flex;
  flex-direction: column;

  padding: size(1) size(2) size(2) size(2);
  gap: size(2);
}
