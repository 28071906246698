@use '../yr' as *;

.generic__container {
  padding: 4em 2em;
  text-align: center;
  pre {
    text-align: left;
  }
}

.generic__logo-link {
  display: inline-block;
}

.generic__logo {
  height: 6em;
  width: 6em;
}

.generic__title {
  margin: 2em 0;
}

.generic__body {
  margin-top: size(2);
}
