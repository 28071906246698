@use '~@nrk/yr-design-tokens/build/scss/colors' as *;

// Design system colors from Figma
// See https://www.figma.com/file/QYLlyWT77kBkp8pUJpwTZg/Yr-Colors?node-id=0%3A1

// Base colors
$base-color-black: #21292b;
$base-color-grey: #56616c;
$base-color-blue: #006edb;
$base-color-red: #c60000;
$base-color-green: #457222;
$base-color-border-grey: #c3d0d8;
$base-color-white: #ffffff;

// Custom colors
$base-color-yr-blue: #00b8f1;
$base-color-roadsign-green: #4ca52c;
$base-color-green-2: #5dca0a;
$base-color-orange: #ff9d00;
$base-color-yellow: #ffe600;
$base-color-purple: #aa00f2;
$base-color-turquoise: #b8e8ed;
$base-color-violet: #dbceff;
$base-color-snowy-grey: #2d5f73;
$base-color-warning-yellow-border: #938616;
$base-color-warning-orange-border: #9a6100;
$base-color-warning-red-border: #721515;
$base-color-astronomical: #043551;
$base-color-nautical: #075078;
$base-color-civil: #106ca0;
$base-color-purple-dark: #3c185a;

// Lightened colors
$base-color-blue-lightened: #cce2f8;
$base-color-red-lightened: #e5c1c2;
$base-color-orange-lightened: #f2d8a9;
$base-color-yellow-lightened: #f2eeb5;
$base-color-green-lightened: #d0e9c4;
$base-color-purple-lightened: #e0c1f2;
$base-color-snowy-grey-lightened: #d5dfe3;

// Faded colors
$base-color-blue-faded: #eef6f8;
$base-color-red-faded: #fcf2f2;
$base-color-orange-faded: #fff9f2;
$base-color-yellow-faded: #fffdf1;
$base-color-green-faded: #edf5e7;
$base-color-purple-faded: #f3e9f8;
$base-color-grey-faded: #edf1f2;

// Dark mode colors
$base-color-midnight: #020a14;
$base-color-coal: #171f28;
$base-color-dusk: #374759;
$base-color-blue-grey: #a2a5b3;
$base-color-red-2: #ff2d3f;
$base-color-purple-2: #c438ff;

// Unofficial
$base-color-grey-test: #9293a0; // Not in "Yr Colors" in Figma

// Gradients
$base-gradient-blue: (
  start: #168df5,
  stop: #0083f5
);
$base-gradient-now-background: (
  start: #c4d9e6,
  stop: #ffffff
);
$base-gradient-homepage: (
  start: #e1f0fa,
  stop: #ffffff
);

$base-gradient-aurora: (
  start: #00ffd1,
  stop: #8325cd
);

$base-pattern-background-light: #d6d6d6;
$base-pattern-background-medium: #a6a6a6;
$base-pattern-background-dark: #686868;
$base-pattern-foreground-light: #969696;
$base-pattern-foreground-medium: #747474;
$base-pattern-foreground-dark: #2a2a2a;

// Patterns
$base-patterns: (
  grey-diagonal-light: (
    background: $base-pattern-background-light,
    foreground: $base-pattern-foreground-light
  ),
  grey-diagonal-medium: (
    background: $base-pattern-background-medium,
    foreground: $base-pattern-foreground-medium
  ),
  grey-diagonal-dark: (
    background: $base-pattern-background-dark,
    foreground: $base-pattern-foreground-dark
  ),
  grey-horizontal: (
    background: $base-pattern-background-light,
    foreground: $base-pattern-foreground-light
  ),
  red-diagonal: (
    background: $base-color-red-faded,
    foreground: $base-color-red-lightened
  ),
  blue-diagonal: (
    background: $base-color-blue-faded,
    foreground: $base-color-blue-lightened
  ),
  light-blue-diagonal: (
    background: $base-color-blue-lightened,
    foreground: $base-color-yr-blue
  ),
  dark-blue-diagonal: (
    background: $base-color-blue,
    foreground: $base-color-blue-lightened
  ),
  purple-diagonal: (
    background: $base-color-purple-faded,
    foreground: $base-color-purple-lightened
  )
);
