// Add a mouse hover effect that is also applied when the element is focused with a keyboard.
@mixin hover($focus-within: false) {
  // We set an `is-hovering` class name on the `<html>` element when the user moves the mouse.
  // We use this class name to set the `:hover` style to prevent elements from accidentally
  // getting a focus style when swiping on touch screens.
  .no-js &:hover,
  .js.is-hovering &:hover,

  // We set an `is-tabbing` class name on the `<html>` element when the user tabs using a keyboard.
  // We use this class name to set the `:focus` style to prevent elements from accidentally
  // getting a focus style when focusing an element when navigating using a mouse,
  // e.g. when opening and closing a dialog and we focus the button that was used to
  // open the dialog by calling `element.focus()`.
  .no-js &:focus,
  .js.is-tabbing &:focus {
    @content;
  }

  // Show the hover effect when an element within has focus also
  @if ($focus-within) {
    .no-js &:focus-within,
    .js.is-tabbing &:focus-within {
      @content;
    }
  }
}

// We need an active mixin also since we can't use just `:active` because of specificity.
@mixin active() {
  .no-js &:active,
  .js.is-hovering &:active,
  .js.is-tabbing &:active {
    @content;
  }
}
