@use '../../styles/yr' as *;

$large-version-gte: $mq-990;

.webcam-modal {
  align-items: center;
  bottom: 0;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-content: center;
  left: 0;
  overflow: scroll;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-animatable-modal;

  &[data-visible='false'] {
    opacity: 0;
  }
}

.webcam-modal__core-dialog {
  display: grid;
  position: relative;
  grid-template-areas:
    'button-left image button-right'
    '. footer .';
  grid-template-columns: auto auto auto;
  grid-template-rows: 1fr auto;
  min-height: 0;
  min-width: 0;
  max-height: 100%;

  // Render in front of the backdrop
  z-index: 1;
}

.webcam-modal__close-button {
  position: fixed;
  right: size(2);
  top: size(2);

  // Render in front of the image
  z-index: 1;

  @include mq-gte($large-version-gte) {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.webcam-modal__backdrop {
  background-color: var(--color-background-webcam);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 150ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.webcam-modal__image-container {
  grid-area: image;
  min-height: 0;
  position: relative;
  justify-self: center;
  border-radius: size(1);
  overflow: hidden;
}

.webcam-modal__image {
  width: 100%;
  height: 100%;
}

.webcam-modal__direction-label {
  position: absolute;
  top: size(2);
  left: size(2);
}

.webcam-modal__button-container {
  display: flex;
  align-items: center;

  &[data-disabled='true'] {
    opacity: 0;
  }

  @include mq-lt($large-version-gte) {
    display: none;
  }
}

.webcam-modal__button-container--left {
  grid-area: button-left;
  padding-right: size(4);
}

.webcam-modal__button-container--right {
  grid-area: button-right;
  padding-left: size(4);
}

.webcam-modal__footer {
  grid-area: footer;

  &[data-single-row='true'] {
    display: flex;
  }
}

.webcam-modal__details {
  display: inline-block;
  padding-right: size(2);

  // Use padding instead of margin so we can easily read the entire element height
  padding-top: size(2);
}

.webcam-modal__attribution {
  margin-top: size(2);
}

.webcam-modal__view-list {
  display: flex;
  flex: 1;
  align-items: center;

  // Use padding instead of margin so we can easily read the entire element height
  padding-top: size(2);

  // Not supported in all browsers, but if it's not supported the thumbnails
  // will just not have a gap which is acceptable.
  gap: size(2);

  .webcam-modal__footer[data-single-row='true'] & {
    justify-content: end;
  }
}

.webcam-modal__view-button {
  position: relative;
  height: rem(60px);
  max-width: rem(107px);
  flex: 1;

  .webcam-modal__footer[data-single-row='true'] & {
    max-width: rem(160px);
    height: rem(90px);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: rem(-4px);
    right: rem(-4px);
    bottom: rem(-4px);
    left: rem(-4px);
    border: rem(1px) solid transparent;
    border-radius: rem(11px);
  }

  &:hover:after {
    border: rem(1px) solid var(--color-stroke-base);
  }

  &[aria-pressed='true']:after {
    border: rem(1px) solid var(--color-stroke-active);
  }
}

.webcam-modal__view-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: rem(8px);
  position: absolute;
  top: 0;
  left: 0;
}

.webcam-modal__view-direction-label {
  position: absolute;
  top: size(1);
  left: size(1);
}
