@use '../variables/colors' as *;

@mixin focus-style() {
  outline: 2px solid var(--color-stroke-active);

  // Only show a focus outline when interacting with elements using a keyboard
  .js:not(.is-tabbing) & {
    outline: none;
  }
}
