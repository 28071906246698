@use '../../styles/yr' as *;

$modal-dialog-padding: size(2 * 2);

.new-user-welcome-modal {
  padding: size(28) size(2) 0 size(2);
  // Remove modal dialog padding from max-width
  max-width: rem(480px) - $modal-dialog-padding;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  gap: size(2);
}

.new-user-welcome-modal__close-button-contianer {
  display: flex;
  justify-content: end;
}

.new-user-welcome-modal__gradient {
  background-image: linear-gradient(transparent, 75%, var(--color-background-base));
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: size(40);
  // Negativ z-index to put the gradient behind the header
  z-index: -1;
}

.new-user-welcome-modal__promo-img-container {
  // Negativ z-index to put it behind the gradient
  z-index: -1;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: end;
  border-radius: 0 rem(7px) 0 0;
  overflow: hidden;
}

.new-user-welcome-modal__circle-container {
  // Negativ z-index to put it behind the gradient
  z-index: -1;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: start;
  border-radius: 0 rem(7px) 0 0;
  overflow: hidden;
}
.new-user-welcome-modal__list-header {
  color: var(--color-text-accent-core-blue);
}

.new-user-welcome-modal__list {
  display: flex;
  flex-direction: column;
  gap: size(2);
  list-style: none;

  padding-bottom: size(9);
}

.new-user-welcome-modal__list-item {
  display: flex;
  gap: size(1);

  color: var(--color-text-accent-core-blue);
}
