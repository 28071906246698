@use '../../styles/yr' as *;

.wind {
  position: relative;

  &[data-tone='primary'] {
    color: var(--color-text-weather-wind-alt);
  }

  &[data-tone='secondary'] {
    color: var(--color-text-darken-purple);
  }

  &[data-tone='plain'] {
    color: var(--color-text-weather-wind);
  }
}

.wind--color {
  color: var(--color-text-weather-wind-alt);
}

.wind--extreme {
  font-weight: $typography-font-weight-bold;
}

// Make room for the wind arrow on the right
.wind--display-arrow {
  padding-right: size(3.5);
}

.wind__container {
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
}

.wind__value {
  display: inline-block;
}

.wind__gust {
  display: inline-block;
  padding-left: size(0.5);
}

.wind__gust--extreme {
  font-weight: $typography-font-weight-bold;
}

.wind__unit {
  display: inline-block;
  padding-left: size(0.5);
}

.wind__arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: size(3);
  height: size(3);
}
