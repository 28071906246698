@use '../../styles/yr' as *;

.page-root {
  flex: 1 0 auto;
  transition: opacity $ui-transition;

  .page-hide & {
    opacity: 0;
  }

  .page-show & {
    opacity: 1;
  }
}

// Set the page root to "position: relative" if it should act as a container.
// Useful for the map page, for example, where we want to be able
// to absolutely position the map to take up the entire size of the page root.
.page-root--container {
  position: relative;
}

.page-root__static-banner {
  display: none;
  margin-top: size(2);
  margin-bottom: size(2);
}

// Show if html has the class name no-js, but not if it also has the class name disabled-js
.page-root__static-banner--old-browser {
  .no-js:not(.disabled-js) & {
    display: block;
  }
}

.page-root__static-banner--disabled-js {
  .disabled-js & {
    display: block;
  }
}
