@use '../../styles/yr' as *;

.notification {
  top: 0;
  padding: 0.9rem 1rem 0.9rem 0.5rem;
  border-bottom: 1px solid white;
}

.notification__content {
  display: flex;
  justify-content: flex-start;
}

.notification__icon {
  margin-right: size(1);
}

.notification__text {
  margin-top: auto;
  margin-bottom: auto;
}

.notification__link {
  // We use display block because cypress has trouble detecting visibility of the element
  // With display block this element gets an offsetWidth and offsetHeight
  display: block;

  text-decoration: underline;
  color: inherit;
  font-weight: inherit;
}
