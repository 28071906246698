@use '../../styles/yr' as *;

.page {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.page__main {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.page__served-by {
  @include mq-lt($mq-675) {
    display: none;
  }
}
