@use '../../styles/yr' as *;

.app-busy-status {
  background-color: var(--color-background-base);
  bottom: 0;
  left: 0;
  list-style-type: none;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 200ms linear;
  width: 100%;
  z-index: $z-index-app-status;
}

.app-busy-status--active {
  opacity: 0.96;
}
