@use '../../styles/yr' as *;

$gradient: #{linear-gradient(
    to right,
    var(--color-fill-active) 0%,
    var(--color-fill-active) 90%,
    var(--color-fill-active) 100%
  )};

.loading-bar {
  display: none;
  height: 0.125em;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-index-popover;
}

.loading-bar[data-is-fetching='true'] {
  display: block;

  .loading-bar__bar {
    animation: loading-bar__bar--animation 0.8s ease-in-out 0.2s infinite;
  }
}

.loading-bar__bar {
  background-image: $gradient;
  display: block;
  height: 100%;
  width: 100%;
}

@keyframes loading-bar__bar--animation {
  from {
    transform: translateX(-105%);
  }
  to {
    transform: translateX(105%);
  }
}
